// A list of components that need to be used outside of a Svelte app (mixed with plain HTML)
const components = {
  'components/admin/HomeImageManager': () => import('./components/admin/HomeImageManager'),
  'components/HeaderBar/DonationHeader': () => import('./components/HeaderBar/DonationHeader.svelte'),
  'components/Share': () => import('./components/Share.svelte'),
}

/*
  This method creates Svelte components from existing HTML tags, allowing individual components to be used outside of a
  Svelte-based application, mixed with plain HTML. The component to be created is specified using the data-component
  prop. For example:

  <div data-component="components/admin/CarouselPhotos.svelte" />
 */
const initialize = (): void => {
  const componentNodes = document.querySelectorAll('[data-component]')

  componentNodes.forEach(node => {
    const componentName = node.getAttribute('data-component') as keyof typeof components

    const componentFn = components[componentName]
    if (componentFn) {
      void componentFn().then(({ default: component }) => {
        const attributes = node.attributes
        const props: { [name: string]: string } = {}
        for (let i = 0; i < attributes.length; i += 1) {
          const attr = attributes.item(i)
          if (attr && attr.name.toLowerCase() !== 'data-component') {
            props[attr.name] = attr.value
          }
        }

        new component({
          target: node,
          props,
        })
      })
    }
  })
}

export default initialize

declare global {
  interface Window {
    initialize: () => void
  }
}

window.initialize = initialize
